//
//
//
//
//
//
//
//
//
//

export default {
  name: "error",
  props: ['error'],
  layout: 'error'
}
