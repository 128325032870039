//
//
//
//
//
//
//
//

import TheHeader from '../components/TheHeader'
import TheFooter from '../components/TheFooter'
import VkMessages from '../components/VkMessages'

export default {
  auth: false,
  name: 'default',
  components: {TheFooter, TheHeader},
  beforeMount() {
    setTimeout(() => {
      [
        'https://www.googletagmanager.com/gtag/js?id=G-0TSLLGRQ22&l=dataLayer&cx=c',
        'https://www.googletagmanager.com/gtm.js?id=GTM-NLFWDGF'
      ].forEach(src => {
          this.createScript(src);
      });

      (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
        m[i].l=1*new Date();
        for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
        k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
      (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

      ym(71027452, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true
      });

      this.addChat();
    }, 5000);
  },
  methods: {
    createScript(src, callback) {
      Object.assign(document.body.appendChild(document.createElement('script')), {
        src,
        async: true,
        onload(e) {
          if(callback) callback(e);
        }
      })
    },
    addChat() {
      if(this.$i18n.locale === 'ru') {
        this.createScript('https://vk.com/js/api/openapi.js?169', (e) => {
          document.body.appendChild(document.createElement('div')).id = 'vk_community_messages';

          VK.Widgets.CommunityMessages('vk_community_messages', 103745867, {
            disableButtonTooltip: 1,
            disableExpandChatSound: 1,
            expanded: 0,
          });
        });
      }
      else {
        this.createScript('https://static.site-chat.me/support/support.int.js?h=3e62f7753836e2eee0a367a3ee21c5dd');
      }
    }
  }
}
