//
//
//
//
//
//
//
//
//
//
//

import Header from '../components/admin/Header'

export default {
  name: 'default',
  components: {Header}
}
